import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faVials, IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface BlogEntry {
  icon: IconDefinition;
  title: string;
  intro: string;
  href: string;
}
const entries: BlogEntry[] = [
  {
    icon: faCalculator,
    title: "What is statistical significance?",
    intro: `One of the important factors in conversion rate optimisation is statistical significance – yet sadly, online resources for statistical significance are generally
    rife with examples that aren’t at all aligned with business goals. Here's how to understand statistical significance from a business perspective.`,
    href: "/blog/what-is-statistical-significance/",
  },

  {
    icon: faVials,
    title: "How to run A/B tests",
    intro: `A/B testing (also known as split testing) is the gift that keeps on giving. When you run A/B tests, you gain valuable insights into how
    your users respond to... well, anything really. The sky's the limit! Running tests allows you to optimise for
    continuous improvement – it’s good for you, and it’s good for your visitors. Here's how to do it.`,
    href: "/blog/how-to-run-ab-tests/"
  },

  {
    icon: faCalculator,
    title: "How to calculate statistical significance",
    intro: `Statistical significance is the probability that your split test’s result was due to random chance. Another way of thinking about this is
    that the smaller the probability that the result was random, the more confident you can be that your changes are what caused the result. Here's how to
    calculate it.`,
    href: "/blog/how-to-calculate-statistical-significance/"
  },
]
const BlogEntries: React.FunctionComponent = () => (
  <div className="row">
    {entries.map(({ href, icon, title, intro }) => (
      <div className="col-xl-4 col-lg-6 py-3" key={href}>
        <Link to={href} className="text-decoration-none">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title text-primary text-center">
                <div>
                  <FontAwesomeIcon icon={icon} />
                </div>
                {title}
              </h5>
              <p className="card-text text-dark">
                {intro}
              </p>
              <p className="text-center text-primary text-bold">
                Read more
            </p>
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

export default BlogEntries