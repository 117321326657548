import React from 'react'
import { TextInput, NumberInput } from '../Input'
import { VariationWithMeta } from './Variation'

interface VariationInputProps {
  variation: VariationWithMeta
  onChange: (variation: VariationWithMeta) => void
}

export const VariationInput: React.FunctionComponent<VariationInputProps> = ({ variation, onChange }) => {
  const useHandleChange = <K extends keyof VariationWithMeta>(key: K) => {
    return React.useCallback((value: VariationWithMeta[K]) => {
      onChange({ ...variation, [key]: value })
    }, [variation, onChange])
  }

  const { label, population, conversions } = variation

  return (
    <React.Fragment>
      <TextInput value={label} onChange={useHandleChange('label')} className="form-control" />
      <NumberInput value={population} onChange={useHandleChange('population')} min={0} className="form-control" />
      <NumberInput value={conversions} onChange={useHandleChange('conversions')} min={0} max={population} className="form-control" />
    </React.Fragment>
  )
}
