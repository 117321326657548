import { BinomialDistribution } from "./BinomialDistribution"

export interface Variation {
  population: number
  conversions: number
}

export interface VariationWithMeta extends Variation {
  label: string
}

export const EmptyVariationMeta: Readonly<VariationWithMeta> = {
  label: '',
  population: 0,
  conversions: 0,
}

export function addVariations(variationA: Variation, variationB: Variation): Variation {
  return {
    population: variationA.population + variationB.population,
    conversions: variationA.conversions + variationB.conversions
  }
}

export function getConversionRateForVariation({ population, conversions }: Variation): number {
  if (population < 1) return 0
  if (conversions < 1) return 0
  if (conversions > population) return 1
  return conversions / population
}
