import React from "react";
import { NormalDistribution } from 'statistics/src/NormalDistribution'
import { VariationWithMeta, EmptyVariationMeta, addVariations, getConversionRateForVariation } from "../../components/StatisticalSignificance/Variation";
import { VariationInput } from "../../components/StatisticalSignificance/VariationInput";
import SEO from "../../components/SEO";
import Layout from "../../components/Layouts/Layout";
import { VerticalSpace } from "../../components/VerticalSpace";
import { Percentage } from "../../components/Percentage";
import Blog from "../blog";
import BlogEntries from "../../components/BlogEntries";

const StatisticalSignificanceCalculator: React.FunctionComponent = () => {
  const [variationA, setVariationA] = React.useState<VariationWithMeta>({
    ...EmptyVariationMeta,
    label: 'A',
  })
  const [variationB, setVariationB] = React.useState<VariationWithMeta>({
    ...EmptyVariationMeta,
    label: 'B',
  })

  const conversionRateA = React.useMemo(
    () => getConversionRateForVariation(variationA),
    [variationA.conversions, variationA.population]
  )

  const conversionRateB = React.useMemo(
    () => getConversionRateForVariation(variationB),
    [variationB.conversions, variationB.population]
  )

  const conversionRateDelta = React.useMemo(
    () => conversionRateA - conversionRateB,
    [conversionRateA, conversionRateB]
  )

  const probability = React.useMemo(() => {
    const variationTotal = addVariations(variationA, variationB)
    const conversionRateTotal = getConversionRateForVariation(variationTotal)
    // <TODO task="understand this variance calculation - Central Limits theorm">
    const tmp = conversionRateTotal * (1 - conversionRateTotal)
    const variance = tmp / variationA.population + tmp / variationB.population
    // </TODO>
    const normalDistributionNullHypothesis = NormalDistribution(0, variance)
    const zScore = normalDistributionNullHypothesis.getStandardScore(conversionRateDelta)
    let probability = normalDistributionNullHypothesis.getCumulativeProbability(zScore)
    if (zScore > 0) {
      probability = 1 - probability
    }
    return 2 * probability
  }, [conversionRateA, conversionRateB])

  const [winner, loser] = (conversionRateA > conversionRateB ? [variationA, variationB] : [variationB, variationA])
  const howMuchBetter = (winner.conversions * loser.population) / (winner.population * loser.conversions) - 1

  return (
    <Layout title="A/B Test Calculator">
      <SEO
        title="AB Test Calculator"
        description="calculator for determining statistical significance of ab test"
      />
      <div className="container statistical-significance">
        <VerticalSpace height={50} />
        <p>
          If you're running A/B tests on social ads, emails, your website, or your app,
          it can be difficult to know if your tests impact on your bottom-line results.
          Enter your tests' variant sample sizes and conversions below to determine
          if your split test was statistically significant.
        </p>
        <VerticalSpace height={50} />
        <form>
          <label className="text-center">Your Label</label>
          <label className="text-center">Sample Size</label>
          <label className="text-center">Conversions</label>
          <label className="text-center">Conversion Rate</label>
          <VariationInput variation={variationA} onChange={setVariationA} />
          <span className="conversion-rate">
            <Percentage value={conversionRateA} />
          </span>
          <VariationInput variation={variationB} onChange={setVariationB} />
          <span className="conversion-rate">
            <Percentage value={conversionRateB} />
          </span>
        </form>
        <VerticalSpace height={75} />
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <section className="card">
              <div className="card-body">
                <h2 className="text-center">Your test results</h2>
                <p>
                  "{winner.label}" converted <Percentage value={howMuchBetter} /> better than "{loser.label}".
                  The changes in "{winner.label}" did improve your conversion rate.
                  We are <Percentage value={1 - probability} /> confident that the changes you made in "{winner.label}"
                  caused the improvement.
                </p>
              </div>
            </section>
          </div>
        </div>
        <VerticalSpace height={100} />
        <div>
          <h3>
            Want to learn more about running A/B tests?
          </h3>
          <BlogEntries />
        </div>
        <VerticalSpace height={100} />
      </div>
    </Layout>
  )
}

export default StatisticalSignificanceCalculator
